@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&family=IBM+Plex+Serif:wght@500&display=swap");

* {
  font-family: "IBM Plex Serif", serif;
}

::root {
  --aid-primary: #ffb83c;
  --aid-secondary: #9bfee7;
}

html,
body {
  background-color: var(--dark-0);
  overflow-x: clip;
}

h3 {
  line-height: 1.4em;
}

button {
  display: flex;
  font-family: "IBM Plex Serif", serif;
  font-weight: 800;
  width: max-content;
  color: var(--dark-0);
  background-color: transparent;
  padding: var(--medium);
  padding-left: var(--large);
  padding-right: var(--large);
  transition: transform 0.3s ease;
  position: relative;
  align-items: center;
  justify-content: center;
}

button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: perspective(1500px) skew(-5deg, 1deg) scale(1.9, 0.9)
    translate(-133px, -2px) rotateY(-60deg) translateZ(-150px);
  background-color: #ffb83c;
  z-index: -1;
  pointer-events: none;
}

button::after {
  pointer-events: none;
  content: "";
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
  border: 3px solid #ffb83c;
  height: 100%;
  width: 100%;
  clip-path: polygon(1% 1%, 99% 0%, 100% 99%, 0 99%);
  transform: perspective(2000px) skew(-5deg, 1deg) scale(1.83, 0.86)
    translate(-4px, -5px) rotateY(-60deg);
}

button.alt {
  color: #ffb83c;
}
button.alt::before {
  background: transparent;
}
button.alt::after {
  transform: perspective(1500px) skew(-5deg, 1deg) scale(1.7, 0.85)
    translateY(-5px) translateX(3px) rotateY(60deg);
}
button.alt:hover::after {
  background-color: #ffb73c18;
}

button:hover {
  transform: scale(1.05);
  /* filter: drop-shadow(0px 0px 16px #ffb73c79); */
}

.aid-top-nav {
  position: fixed;
  top: 0;
  z-index: 999;
  height: var(--c9);
  width: 100vw;
  background: var(--dark-1);
  box-sizing: border-box;
  padding: var(--medium);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
}

.aid-top-nav button {
  color: #ffb83c;
  background: radial-gradient(
    ellipse at center bottom,
    #a5741e -100%,
    #ffb73c02 100%
  );
  border-radius: 0;
  padding-left: var(--extraLarge);
  padding-right: var(--extraLarge);
  border: 2px solid #ffb73c71;
  box-sizing: border-box;
  max-height: 100%;
  border-radius: 8px;
}
.aid-top-nav button::after,
.aid-top-nav button::before {
  display: none;
}

.video-top {
  max-width: 1016px;
  width: calc(100% - var(--c6));
  margin: var(--medium) auto;
  height: 0;
  margin-bottom: 0;
  animation: fade-left 0s ease forwards;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  z-index: 3;
}
.video-top video {
  border: 1px solid var(--dark-2);
}

.aid-eyebrow {
  font-weight: bold;
  width: max-content;
  color: var(--dark-0);
  padding: var(--extraSmall);
  padding-left: var(--medium);
  padding-right: var(--medium);
  position: relative;
  left: calc(var(--small) * -1);
}
.aid-eyebrow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  clip-path: polygon(6% 6%, 100% 0%, 100% 95%, 0 95%);
  background-color: var(--dark-5);
  z-index: -1;
}

.aid-eyebrow::after {
  content: "";
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
  border: 2px solid var(--dark-5);
  height: 100%;
  width: 100%;
  clip-path: polygon(1.5% 1.5%, 100% 0%, 100% 97%, 0 95%);
  transform: skew(-2deg, 2deg) scale(0.97, 0.8);
}

.relative-wrapper {
  max-width: 1016px;
  width: calc(100% - var(--c6));
  margin: var(--medium) auto;
  position: relative;
  min-height: 0;
  height: 0;
  max-height: 0;
}

.aid-guide-image {
  pointer-events: none;
  background-image: url("https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/7295647b-9d19-4b73-ba99-a02690c33d00/public");
  background-size: contain;
  background-position: center;
  height: 800px;
  width: 600px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  right: -80px;
  z-index: 3;
}

.aid-rune-base {
  pointer-events: none;
  background-image: url("https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/d30fb39c-a8bc-4c4b-087b-387119969200/public");
  background-size: contain;
  background-position: center;
  height: 876px;
  width: 876px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -200px;
  left: -25%;
  z-index: 0;
}

.aid-layer-3 {
  pointer-events: none;
  background-image: url("https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/22aec7e4-3e62-4dd5-9640-1ee2ccc2ea00/public");
  background-size: contain;
  background-position: center;
  height: 770px;
  width: 770px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -84px;
  left: -26.3%;
  z-index: 0;
}

.aid-layer-2 {
  pointer-events: none;
  background-image: url("https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/56774fd7-62f8-48ac-3c85-5dfd02f3d200/public");
  background-size: contain;
  background-position: center;
  height: 770px;
  width: 770px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -84px;
  left: -26.3%;
  z-index: 0;
}

.aid-layer-1 {
  pointer-events: none;
  background-image: url("https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/39ae59bc-ea23-45ba-a9e6-b7050869b600/public");
  background-size: contain;
  background-position: center;
  height: 770px;
  width: 770px;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -84px;
  left: -26.3%;
  z-index: 0;
}

.step-card {
  margin-bottom: var(--small);
}

.step-card-content {
  position: relative;
  padding: var(--large);
  color: var(--dark-5);
  background-image: url("https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/22aec7e4-3e62-4dd5-9640-1ee2ccc2ea00/public");
  background-size: 1000%;
  background-position: center;
  margin-left: var(--extraSmall);
  margin-right: var(--extraSmall);
  padding-top: var(--small);
}

.step-card-content::after {
  content: "";
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
  border: 3px solid #928a83ad;
  height: 100%;
  width: 100%;
  clip-path: polygon(1% 1%, 100% 0%, 100% 99.5%, 0 99%);
  transform: skew(-0.5deg, 0.5deg) translate(-3px, -3px) scale(1);
}

.step-card-content .number-circle {
  background-color: #ffb83c;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--c5);
  height: var(--c5);
  border-radius: 999px;
  font-weight: bold;
  color: #362f2a;
}

.step-card:nth-of-type(2) {
  transform: translateY(55%);
}

.skew-card {
  cursor: pointer;
  position: relative;
  padding: var(--large);
  top: 0;
  transition: top 0.3s ease;
  display: flex;
  /* border: 1px solid red; */
}

.skew-card::after {
  content: "";
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
  border: 1px solid var(--dark-2);
  background-color: var(--dark-1);
  height: 100%;
  width: 100%;
  transform: perspective(3000px) skew(-1deg, 1deg) scale(1, 1)
    translate(3px, 8px) rotateY(10deg) translateZ(-1);
  z-index: -2;
}

.skew-card:nth-of-type(2)::after {
  content: "";
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
  border: 1px solid var(--dark-2);
  background-color: var(--dark-1);
  height: 100%;
  width: 100%;
  transform: perspective(3000px) skew(-2deg, 0deg) scale(1.05, 1)
    translate(-40px, 0px) rotateY(-20deg) translateZ(-100px);
  z-index: -1;
}

.skew-card:hover {
  top: -16px;
}

.play-button {
  background-color: #ffb83c;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--c6);
  height: var(--c6);
  border-radius: 999px;
  font-weight: bold;
  background-image: url("https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/79b0fb4d-5b23-403a-88e2-f6e769860900/public");
  background-position: 60% center;
  background-size: 45%;
  background-repeat: no-repeat;
}

.steps-card-image {
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  padding-top: 61%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 2;
  /* filter: brightness(2); */
}

/* .steps-card-image::after {
  content: '';
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
  border: 3px solid var(--dark-3);
  height: 100%;
  width: 100%;
  clip-path: polygon(1% 1%, 100% 0%, 100% 99.5%, 0 99%);
  transform: skew(-.5deg, .5deg) translate(-3px, -3px) scale(1.01);
} */

.plan-card {
  position: relative;
  padding: var(--large);
  height: max-content;
  /* border: 1px solid red; */
}
.plan-card-hero {
  box-sizing: border-box;
  padding: var(--large);
  /* background-color: rgba(0, 0, 0, 0.3); */
}

/* .plan-card-hero::after {
  content: "";
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
  background-color: var(--dark-1);
  border: 2px solid rgba(255, 255, 255, 0.05);
  height: 100%;
  width: 100%;
  transform: perspective(3000px) scale(1.05, 1) skew(-1deg, 1deg)
    translate(3px, 8px) rotateY(10deg) translateX(16px) translateZ(-100px);
  z-index: -1;
} */

.plan-card::after {
  content: "";
  top: 0;
  left: 0;
  display: inline-block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  /* border: 2px solid var(--dark-5);; */
  height: 100%;
  width: 100%;
  clip-path: polygon(0.2% 0.2%, 99.5% 0%, 100% 99.9%, 0 99.5%);
  transform: perspective(3000px) skew(-1deg, 1deg) scale(1, 1)
    translate(3px, 8px) rotateY(10deg) translateZ(-100px);
  z-index: -1;
}

.plan-card .aid-eyebrow {
  z-index: 99;
}

.plan-card.gold::after {
  border-color: #ffb83c;
}
.plan-card.gold .aid-eyebrow::after {
  border-color: #ffb83c;
}
.plan-card.gold .aid-eyebrow::before {
  background-color: #ffb83c;
}

.plan-card.platinum::after {
  border-color: #9bfee7;
}
.plan-card.platinum .aid-eyebrow::after {
  border-color: #9bfee7;
}
.plan-card.platinum .aid-eyebrow::before {
  background-color: #9bfee7;
}

@keyframes aid-fade-hero {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes reveal-up {
  from {
    transform: translateY(16px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.reveal-1 {
  opacity: 0;
  animation: reveal-up 1s ease forwards;
  animation-delay: 1.5s;
}
.reveal-2 {
  opacity: 0;
  animation: reveal-up 1s ease forwards;
  animation-delay: 2.8s;
}
.reveal-3 {
  opacity: 0;
  animation: reveal-up 1s ease forwards;
  animation-delay: 3s;
}
.reveal-0 {
  opacity: 0;
  animation: reveal-up 1s ease forwards;
  animation-delay: 0.5s;
}

@keyframes aid-reveal {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 0px -3748px;
  }
}

@keyframes bobble {
  0% {
    transform: translateY(0px) translateX(-50%);
  }
  50% {
    transform: translateY(-24px) translateX(-50%);
  }
  100% {
    transform: translateY(0px) translateX(-50%);
  }
}

.lat-logo {
  height: var(--medium);
}

.aid-logo {
  background-image: url("https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/2d979540-cc88-4111-d4e8-2499811ecb00/foil");
  background-size: 100%;
  height: 96px;
  width: 557px;
  animation: aid-reveal 2s steps(39) forwards;
  animation-delay: 1s;
}

.scroll-prompt {
  pointer-events: none;
  color: #fff;
  z-index: 3;
  padding: var(--extraLarge);
  left: 50%;
  box-sizing: border-box;
  animation: bobble 3s ease-in-out infinite;
}

.scroller-transition {
  pointer-events: none;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  position: fixed;
  bottom: 0;
  animation: reveal-up 1s ease forwards;
  animation-delay: 1s;
}

.scroll-prompt::after {
  position: absolute;
  left: 50%;
  content: "";
  height: 100%;
  border-left: 1px solid #fff;
  transform: translateY(32px);
}

@keyframes aid-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.aid-spinner {
  height: var(--medium);
  width: var(--medium);
  border-left: 2px solid #fff;
  border-top: 2px solid #fff;
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-radius: 999px;
  animation: aid-spin 0.5s linear infinite;
}

.app-download-overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 110%);
  pointer-events: all;
  transition: clip-path 0.5s ease;
}

.app-download-tab {
  flex: 1;
  text-align: center;
  padding: var(--small);
  box-sizing: border-box;
  border: 2px solid transparent;
  cursor: pointer;
  margin: 0;
  border-radius: 8px;
}

.app-download-tab.active {
  color: #ffb83c;
  background: radial-gradient(
    ellipse at center bottom,
    #a5741e -100%,
    #ffb73c02 100%
  );
  border: 2px solid #ffb83c;
}

.app-download-panel-container {
  width: 336px;
  max-width: 336px;
}

.app-download-panel {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-sizing: border-box;
  padding: var(--small);
}

.app-download-segmented {
  box-sizing: border-box;
  padding-bottom: 0;
}

.aid-qr {
  width: calc(336px - calc(var(--small) * 2));
  height: calc(336px - calc(var(--small) * 2));
  animation: reveal-up 0.3s ease;
  box-sizing: border-box;
}

.app-download-overlay.retracted {
  pointer-events: none;
  clip-path: polygon(0 0, 100% 0%, 100% 0, 0 0);
}

.feature-row {
  align-items: center;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: var(--c2);
}

.feature-row p {
  display: flex;
  gap: var(--c1);
}

.feature-row p:nth-of-type(1) {
  opacity: 0.5;
  text-transform: uppercase;
  font-weight: bold;
}

.feature-row p:nth-of-type(2):before {
  content: "";
  display: inline-block;
  max-height: var(--c3);
  max-width: var(--c3);
  min-height: var(--c3);
  min-width: var(--c3);
  border-radius: 999px;
  background-color: var(--dark-5);
  mix-blend-mode: lighten;
  opacity: 0.3;
  background-image: url(https://voyage-images.s3.us-east-2.amazonaws.com/x-black.png);
  background-position: center;
  background-size: cover;
}

.feature-row p:nth-of-type(3):before {
  content: "";
  display: inline-block;
  max-height: var(--c3);
  max-width: var(--c3);
  min-height: var(--c3);
  min-width: var(--c3);
  border-radius: 999px;
  background-color: #ffb83c;
  background-image: url(https://voyage-images.s3.us-east-2.amazonaws.com/check-black.png);
  background-position: center;
  background-size: cover;
}

.feature-check {
  display: flex;
  gap: var(--c1);
}

.feature-check:before {
  content: "";
  display: inline-block;
  max-height: var(--c3);
  max-width: var(--c3);
  min-height: var(--c3);
  min-width: var(--c3);
  border-radius: 999px;
  background-color: rgba(255, 255, 255, 0.1);
  background-image: url(https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/74490b9d-0287-462b-a324-20d05b4f7f00/public);
  background-position: center;
  background-size: cover;
}

.feature-check.x:before {
  background-color: rgba(0, 0, 0, 0.5);
  background-image: url(https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/1ea6d2e7-1143-4d40-ee82-08e2e6ab3f00/public);
}

.shrink-gap {
  gap: 24px;
}

/* TABLET BREAK */
@media only screen and (max-width: 1090px) {
  .rune-wheel {
    transform: translateX(-100px);
  }
}

/* MOBILE BREAK */
@media only screen and (max-width: 765px) {
  @keyframes aid-reveal {
    from {
      background-position: 0px 0px;
    }
    to {
      background-position: 0px -1778px;
    }
  }
  .shrink-gap {
    gap: 16px;
  }

  .lat-logo {
    height: var(--small);
  }
  .home-buttons {
    width: 100%;
    flex-direction: column !important;
  }
  .aid-logo {
    width: 264px;
    height: 46px;
  }

  h4.scale {
    font-size: 16px;
  }
  h4.scale br {
    display: none;
  }

  button::after,
  button::before {
    display: none;
  }
  button {
    width: 100%;
    height: 40px;
    border-radius: 0;
    color: #000;
    font-size: 14px;
    background-color: #ffb83c;
  }
  button.alt {
    border: 2px solid #ffb83c;
    background-color: transparent;
  }
  .scroll-prompt {
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .ai-dungeon-landing-section.hero {
    padding-bottom: 24px !important;
  }
  .ai-dungeon-landing-section.hero .ai-dungeon-landing-section-inner {
    max-height: 50vh;
  }
  .aid-top-nav {
    align-items: center;
    justify-content: center;
    height: var(--extraLarge);
  }
  .aid-top-nav button {
    display: none;
  }
  .step-card:nth-of-type(2) {
    transform: translateY(0);
  }
  .aid-guide-image {
    display: none;
  }
  .rune-wheel {
    position: relative;
    bottom: -200px;
    transform: scale(0.5) translateX(-50%);
  }
  .plan-card-hero {
    padding: var(--c1);
    /* background-color: rgba(0, 0, 0, 0.3); */
  }
  .feature-row p:nth-of-type(1) {
    display: none;
  }
  .plan-card-hero h4:nth-of-type(1) {
    display: none;
  }
}
