/* Root variables */

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&family=IBM+Plex+Serif:wght@500&display=swap');

@keyframes fade-left {
  from {
    opacity: 0;
    transform: translateX(var(--medium));
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

:root {
  --primary: #e2e600;
  --secondary: #00abff;
  --red: #ff3b20;
  --orange: #ff8800;
  --dark-0: #000000;
  --dark-1: #181819;
  --dark-2: #2c2c2d;
  --dark-3: #404142;
  --dark-4: #606163;
  --dark-5: #fff;

  --nav-height: var(--c10);

  --c1: 8px;
  --c2: 16px;
  --c3: 24px;
  --c4: 32px;
  --c5: 40px;
  --c6: 48px;
  --c7: 56px;
  --c8: 64px;
  --c9: 72px;
  --c10: 80px;
  --c11: 88px;
  --c12: 96px;
  --c13: 104px;
  --c14: 112px;
  --c15: 120px;
  --c16: 128px;
  --c17: 136px;
  --c18: 144px;
  --c19: 152px;
  --c20: 160px;
  --c21: 168px;
  --c22: 176px;
  --c23: 184px;
  --c24: 192px;
  --c25: 200px;
  --c26: 208px;
  --c27: 216px;
  --c28: 224px;
  --c29: 232px;
  --c30: 240px;
  --c31: 248px;
  --c32: 256px;
  --c33: 264px;
  --c34: 272px;
  --c35: 280px;
  --c36: 288px;
  --c37: 296px;
  --c38: 304px;
  --c39: 312px;
  --c40: 320px;
  --c41: 328px;
  --c42: 336px;
  --c43: 344px;
  --c44: 352px;
  --c45: 360px;
  --c46: 368px;
  --c47: 376px;
  --c48: 384px;
  --c49: 392px;
  --c50: 400px;
  --c51: 408px;
  --c52: 416px;
  --c53: 424px;
  --c54: 432px;
  --c55: 440px;
  --c56: 448px;
  --c57: 456px;
  --c58: 464px;
  --c59: 472px;
  --c60: 480px;
  --c61: 488px;
  --c62: 496px;
  --c63: 504px;
  --c64: 512px;

  --extraSmall: 8px;
  --small: 16px;
  --medium: 24px;
  --large: 40px;
  --extraLarge: 64px;

  --text-body-small: 12px;
  --text-body-medium: 14px;
  --text-body-large: 16px;

  --text-header-small: 18px;
  --text-header-medium: 22px;
  --text-header-large: 33px;

  --text-display: 54px;
  --text-display-large: 64px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'IBM Plex Serif', sans-serif;
  color: #fff;
  background-color: #181819;
  overflow-x: hidden;
  outline: var(--primary);
  min-height: 100vh;
}

h1 {
  font-size: var(--text-display-large);
  margin-top: -21px;
  margin-bottom: -17px;
  line-height: var(--text-display-large);
}
h2 {
  font-size: var(--text-display);
  margin-top: -17px;
  margin-bottom: -15px;
  line-height: var(--text-display-large);
}
h3 {
  font-size: var(--text-header-large);
  margin-top: -11px;
  margin-bottom: -9px;
  line-height: var(--large) !important;
}
h4 {
  font-size: var(--text-header-medium);
  margin-top: -7px;
  margin-bottom: -6px;
}
h5 {
  font-size: var(--text-header-small);
  margin-top: -5px;
  margin-bottom: -5px;
}
p {
  font-size: 16px;
  margin-top: -5px;
  margin-bottom: -5px;
  line-height: var(--medium);
}
li {
  font-size: 16px;
  margin-top: -5px;
  margin-bottom: -5px;
  line-height: var(--medium);
}
ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 24px;
}
p.label {
  font-size: calc(var(--text-header-small) / 1.2);
  font-family: 'IBM Plex Mono';
  text-transform: uppercase;
  position: relative;
  top: var(--c1);
  opacity: 0.75;
}
hr {
  width: 100%;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin: 0;
}

/*****************/
/** Page Wrapper */
/*****************/
.voyage-page-wrapper {
  max-width: 1392px;
  width: calc(100% - var(--c6));
  margin: var(--medium) auto;
  margin-top: calc(var(--nav-height) + var(--large));
  padding-bottom: calc(var(--nav-height) + var(--large));
  margin-bottom: 0;
  min-height: calc(100vh - calc(calc(var(--nav-height) + var(--large)) * 2));
  animation: fade-left 0.3s ease forwards;
  display: flex;
  flex-direction: column;
}
.voyage-page-wrapper.small {
  max-width: 1040px;
}
.voyage-page-wrapper.smallest {
  max-width: 512px;
}

/*****************/
/** Navigation ***/
/*****************/
.voyage-top-navigation {
  height: var(--nav-height);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* background-color: var(--dark-2); */
  z-index: 9;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  gap: var(--extraSmall);
  /* background: rgba(40,40,42,0.7);
  box-shadow: inset 0px 0px 40px rgba(40,40,42,0.9);
  border-top: 1px solid rgba(255,255,255,0.2); */
  backdrop-filter: blur(12px);
}
.nav-logo {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: var(--medium);
  height: 100%;
  width: var(--c27);
  background-size: 100%;
  background-image: url('https://voyage-images.s3.us-east-2.amazonaws.com/voyage-logo-full.svg');
  background-position: center 53%;
  background-repeat: no-repeat;
}
.mobile-nav-logo {
  position: relative;
  cursor: pointer;
  height: 100%;
  width: var(--c15);
  background-size: 213%;
  background-image: url('https://voyage-images.s3.us-east-2.amazonaws.com/voyage-logo-full.svg');
  background-position: right 53%;
  background-repeat: no-repeat;
  right: 20px;
}

.mobile-nav-logo::after,
.nav-logo::after {
  content: 'BETA';
  font-size: 10px;
  font-weight: bold;
  position: absolute;
  right: -42px;
  top: 50%;
  background-color: var(--dark-5);
  padding-bottom: 1px;
  height: 16px;
  width: 36px;
  border-radius: 2px;
  color: var(--dark-0);
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-user-section {
  position: absolute;
  top: 0;
  right: var(--medium);
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-user-bubble {
  height: var(--c6);
  width: var(--c6);
  background-color: var(--dark-4);
  border-radius: 50%;
  cursor: pointer;
  background-image: url('https://voyage-images.s3.us-east-2.amazonaws.com/unknown-user.png');
  background-position: center;
  background-size: 110%;
  box-sizing: border-box;
  border: 4px solid var(--dark-1);
}
.nav-user-bubble:hover {
  border: 4px solid var(--dark-3);
}
.voyage-mobile-navigation .voyage-top-navigation-item {
  display: none;
}
.voyage-top-navigation-item {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--c20);
  font-size: var(--text-header-small);
  gap: var(--extraSmall);
  box-sizing: border-box;
  color: #fff;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  letter-spacing: 0.03em;
}
.voyage-top-navigation-item.active {
  font-weight: bold;
  letter-spacing: 0em;
}

.voyage-mobile-navigation {
  display: none;
}

@keyframes nav-reveal {
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}
.voyage-top-navigation-item .activity-light {
  position: absolute;
  content: '';
  height: 4px;
  width: 100%;
  background-color: var(--secondary);
  bottom: 0;
  border-radius: 8px 8px 0 0;
  transform: scale(0.8, 1);
  opacity: 0;
}
.voyage-top-navigation-item.active .activity-light {
  animation: nav-reveal 0.3s ease forwards;
}
.nav-icon {
  height: var(--large);
  width: var(--large);
  background-position: calc(var(--extraSmall) * -0.5);
  background-repeat: no-repeat;
}
.nav-icon.compass {
  background-image: url('https://voyage-images.s3.us-east-2.amazonaws.com/compass.svg');
}
.nav-icon.hammer {
  background-image: url('https://voyage-images.s3.us-east-2.amazonaws.com/hammer.svg');
}
.nav-icon.close {
  background-image: url('https://voyage-images.s3.us-east-2.amazonaws.com/close.svg');
}
.nav-icon.dashboard {
  background-image: url('https://voyage-images.s3.us-east-2.amazonaws.com/dashboard.svg');
}
.active .nav-icon.compass {
  background-image: url('https://voyage-images.s3.us-east-2.amazonaws.com/compass-active.svg');
}
.active .nav-icon.hammer {
  background-image: url('https://voyage-images.s3.us-east-2.amazonaws.com/hammer-active.svg');
}
.active .nav-icon.dashboard {
  background-image: url('https://voyage-images.s3.us-east-2.amazonaws.com/dashboard-active.svg');
}
.nav-mobile-visible {
  display: none;
}
.nav-mobile-visible-flex {
  display: none !important;
}

/*****************/
/**** Layout *****/
/*****************/
.voyage-flex-layout {
  display: flex;
}

.voyage-flex-layout.vertical {
  flex-direction: column;
}

.voyage-flex-layout.horizontal {
  flex-direction: row;
}

.voyage-flex-layout.center-y {
  justify-content: center;
}

.voyage-flex-layout.center-x {
  align-items: center;
}

.voyage-page-backdrop {
  position: fixed;
  z-index: 0;
  opacity: 0.15;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  pointer-events: none;
}

/*****************/
/***** Panel *****/
/*****************/

.voyage-panel {
  border-radius: var(--small);
  border: 1px solid var(--dark-3);
  background-color: var(--dark-2);
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  position: relative;
  display: flex;
}
.voyage-panel.rounder {
  border-radius: var(--medium);
}
.voyage-panel.sharp {
  border-radius: var(--extraSmall);
}
.voyage-panel.sharper {
  border-radius: 0;
}
.voyage-panel.borderless {
  border: 0;
}
.voyage-panel.hover {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
}
.voyage-panel.hover:hover {
  z-index: 2;
  transform: scale(1.01);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
  filter: brightness(1.1);
  border-color: var(--dark-5);
}
.voyage-panel.hover:active {
  transform: scale(0.98);
  filter: brightness(0.9);
}
.voyage-panel-overlay {
  flex: 1;
  box-sizing: border-box;
  background: transparent;
  display: flex;
}

/*****************/
/**** Button *****/
/*****************/
button,
.voyage-button {
  display: inline-flex;
  font-family: 'IBM Plex Sans', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  border: 0;
  border-radius: var(--extraSmall);
  gap: var(--extraSmall);
  flex-direction: row;
  padding-top: var(--small);
  padding-bottom: var(--small);
  padding-left: var(--medium);
  padding-right: var(--medium);
  align-items: center;
  justify-content: center;
  font-size: var(--text-body-large);
  background-color: var(--primary);
  cursor: pointer;
  color: var(--dark-0);
  transition: filter 0.1s ease, background-color 0.1s ease;
  height: max-content;
  width: max-content;
  letter-spacing: 0.03em;
}
button.secondary,
.voyage-button.secondary {
  background-color: var(--secondary);
}
button.tertiary,
.voyage-button.tertiary {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--dark-5);
}
button.disabled,
.voyage-button.disabled {
  pointer-events: none;
  background-color: var(--dark-4);
  opacity: 0.5;
}
button:hover,
.voyage-button:hover {
  filter: brightness(1.1);
}
.voyage-button.tertiary:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/*****************/
/***** Input ****/
/*****************/
input[type='text'],
input[type='email'],
input[type='password'] {
  display: inline-flex;
  font-family: 'IBM Plex Sans', sans-serif;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--extraSmall);
  gap: var(--extraSmall);
  flex-direction: row;
  padding-top: var(--small);
  padding-bottom: var(--small);
  padding-left: var(--medium);
  padding-right: var(--medium);
  align-items: center;
  justify-content: center;
  font-size: var(--text-body-large);
  color: var(--dark-5);
  transition: filter 0.1s ease;
  height: max-content;
  letter-spacing: 0.03em;
  box-sizing: border-box;
}
input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus {
  outline: none;
  background-color: var(--dark-1);
  border: 1px solid var(--secondary);
}

/*****************/
/***** Switch ****/
/*****************/
.voyage-switch {
  position: relative;
  display: inline-block;
  max-width: var(--c6);
  max-height: var(--c3);
}

.voyage-switch input[type='checkbox'] {
  width: var(--c6);
  height: var(--c3);
  max-width: var(--c6);
  max-height: var(--c3);
  background-color: #aff;
  cursor: pointer;
  margin: 0;
}

.voyage-switch-track {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--dark-2);
  transition: background-color 0.3s ease;
  pointer-events: none;
  border-radius: 999px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
}

.voyage-switch-track:after {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  top: calc(var(--extraSmall) / 2);
  left: calc(var(--extraSmall) / 2);
  background-color: var(--dark-5);
  transition: transform 0.3s ease;
  pointer-events: none;
  border-radius: 999px;
}

.voyage-switch-track::before {
  font-family: 'IBM Plex Mono';
  font-size: var(--text-body-medium);
  content: 'OFF';
  position: absolute;
  left: calc(var(--large) * -1);
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
}

.voyage-switch input[type='checkbox']:checked + .voyage-switch-track {
  background-color: var(--primary);
}

.voyage-switch input[type='checkbox']:checked + .voyage-switch-track:after {
  transform: translateX(24px);
  background-color: #404142;
}

.voyage-switch input[type='checkbox']:checked + .voyage-switch-track:before {
  content: 'ON';
}

/*****************/
/** Range Slider */
/*****************/

input[type='range'].voyage-range-slider {
  -webkit-appearance: none;
  background: transparent;
  flex: 1;
}
input[type='range'].voyage-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: var(--medium);
  width: var(--medium);
  background-color: var(--dark-5);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 999px;
  transform: translateY(-40%);
  border: 0;
}
input[type='range'].voyage-range-slider::-moz-range-thumb {
  -webkit-appearance: none;
  height: var(--medium);
  width: var(--medium);
  background-color: var(--dark-5);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 999px;
  border: 0;
}
input[type='range']:focus {
  outline: none;
}
input[type='range'].voyage-range-slider::-webkit-slider-runnable-track {
  height: 4px;
  border-radius: 999px;
  background-color: var(--dark-3);
}
input[type='range'].voyage-range-slider::-moz-range-track {
  height: 4px;
  border-radius: 999px;
  background-color: var(--dark-3);
}
.range-value {
  font-family: 'IBM Plex Mono';
  font-size: var(--text-body-medium);
  opacity: 0.5;
}

/*****************/
/**** Section ****/
/*****************/
.voyage-section {
  margin-top: var(--extraLarge);
}
.voyage-section-header {
  margin-bottom: var(--medium);
}

/*****************/
/* Grid (Desktop) /
/*****************/
.voyage-grid {
  display: grid;
}
.voyage-grid.desktop-col-8 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.voyage-grid.desktop-col-6 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.voyage-grid.desktop-col-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.voyage-grid.desktop-col-3 {
  grid-template-columns: 1fr 1fr 1fr;
}
.voyage-grid.desktop-col-2 {
  grid-template-columns: 1fr 1fr;
}
.desktop-span-8 {
  grid-column: span 8;
}
.desktop-span-7 {
  grid-column: span 7;
}
.desktop-span-6 {
  grid-column: span 6;
}
.desktop-span-5 {
  grid-column: span 5;
}
.desktop-span-4 {
  grid-column: span 4;
}
.desktop-span-3 {
  grid-column: span 3;
}
.desktop-span-2 {
  grid-column: span 2;
}
.desktop-span-1 {
  grid-column: span 1;
}

/*****************/
/***** Tables ****/
/*****************/
.voyage-table-row-outer {
  display: flex;
}
.voyage-table-row {
  flex: 1;
  display: flex;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: var(--medium);
  padding-bottom: var(--medium);
  box-sizing: border-box;
  padding-right: var(--medium);
}
.voyage-table-row-outer.clickable {
  cursor: pointer;
}
.voyage-table-row-outer:first-of-type .voyage-table-row {
  border-color: transparent;
}

/*****************/
/***** Modal ****/
/*****************/
@keyframes modal {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes modal-mobile {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(0);
  }
}

.modal-transition-container {
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  width: 100vw;
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 999;
  overflow: hidden;
}

.modal-bg {
  color: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  overflow: hidden;
  overflow: hidden;
}

.modal-panel {
  animation: modal 0.3s ease-out;
  overflow: hidden;
  border: 1px solid #414141;
  background-color: var(--dark-1);
  border-radius: 16px;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  width: var(--c54);
  min-height: var(--c42);
  max-height: var(--c64);
  text-align: center;
}
.wide-modal-panel {
  animation: modal 0.3s ease-out;
  overflow: hidden;
  border: 1px solid #414141;
  background-color: var(--dark-1);
  border-radius: 16px;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  width: calc(50% - 4px);
  text-align: center;
}

.modal-panel.alt,
.wide-modal-panel.alt {
  border: 0;
  text-align: left;
  box-shadow: none;
  background-color: transparent;
  border-radius: 0;
  padding: var(--large);
}

.modal-action-strip {
  box-sizing: border-box;
  border-top: 1px solid var(--dark-2);
  display: flex;
  flex-direction: row;
  padding: var(--small);
  margin-top: var(--small);
  width: 100%;
  gap: var(--small);
}

.modal-action-strip button {
  flex: 1;
}
.modal-action-strip.vertical {
  flex-direction: column;
}
.modal-action-strip.vertical button {
  flex: none;
}

.modal-panel-content {
  display: flex;
  flex-direction: column;
  padding: var(--medium);
  overflow-y: auto;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.modal-preview {
  animation: modal 0.3s ease-out;
  overflow: hidden;
  border: 1px solid #414141;
  background-color: var(--dark-1);
  border-radius: var(--medium);
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  width: 1090px;
  max-width: 1090px;
  margin: var(--small);
  text-align: center;
  position: relative;
}

.modal-preview button {
  backdrop-filter: blur(20px);
  border: 1px solid var(--dark-5);
}

.modal-bg-preview {
  color: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  overflow: hidden;
  overflow: hidden;
}
.preview-button {
  height: var(--c4);
  width: var(--c4);
  max-height: var(--c4);
  max-width: var(--c4);
  border-radius: 999px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-header-medium);
  padding: 0;
  backdrop-filter: blur(16px);
  background-color: rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.5) !important;
  color: var(--dark-5);
  font-weight: normal;
  cursor: pointer;
  padding-bottom: calc(var(--c1) / 3);
}
.modal-preview-bar {
  position: absolute;
  width: 100%;
  gap: var(--c1);
  top: 0;
  left: 0;
  padding: var(--c1);
  padding-bottom: var(--c3);
  box-sizing: border-box;
  text-align: left;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
  display: inline-flex;
}

.preview-button.arrow {
  padding: 0;
  font-size: var(--text-body-small);
}
.preview-button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

/*****************/
/** Info-Labels **/
/*****************/
@keyframes info-label {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.voyage-info-label {
  font-family: 'IBM Plex Mono';
  border: 1px solid var(--secondary);
  color: var(--secondary);
  border-radius: var(--c1);
  padding: var(--c2);
  display: flex;
  flex-direction: row;
  gap: var(--c2);
  align-items: center;
  animation: info-label 0.7s ease;
  background-color: #00aaff1e;
}
.voyage-info-label.error {
  border-color: var(--red);
  background-color: #ff3a201e;
  color: var(--red);
}
.voyage-info-label.warn {
  border-color: var(--orange);
  background-color: #ff72201e;
  color: var(--orange);
}
.voyage-info-label .before {
  display: inline-flex;
  border: 2px solid var(--secondary);
  max-height: var(--c3);
  max-width: var(--c3);
  min-height: var(--c3);
  min-width: var(--c3);
  font-weight: bold;
  border-radius: 999px;
  align-items: center;
  justify-content: center;
}
.voyage-info-label.error .before {
  border-color: var(--red);
}
.voyage-info-label.warn .before {
  border-color: var(--orange);
}

/************************/
/** Segmented Controls **/
/************************/

.voyage-segmented-pill {
  min-height: var(--c5);
  max-height: var(--c5);
  font-size: var(--text-body-large);
  font-family: 'IBM Plex Mono';
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 999px;
  padding-left: var(--medium);
  padding-right: var(--medium);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--dark-5);
  cursor: pointer;
  transition: background-color 0.1s ease;
}

.voyage-segmented-pill:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.voyage-segmented-pill.active {
  background-color: var(--dark-5);
  color: var(--dark-0);
}
