.ai-dungeon-landing-section {
  position: relative;
  width: 100vw;
  box-sizing: border-box;
}

.ai-dungeon-landing-section.light {
  background-image: url("https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/fd5077bf-f178-4d1c-6a7c-88640cb79800/public");
  background-size: cover;
  background-position: center;
  padding-top: calc(var(--extraLarge) * 2);
  padding-bottom: calc(var(--extraLarge) * 2);
  clip-path: polygon(0 5%, 100% 0%, 100% 95%, 0% 100%);
  margin-top: calc(var(--extraLarge) * -1);
  margin-bottom: calc(var(--extraLarge) * -1);
}
.ai-dungeon-landing-section.hero {
  /* background-image: url('https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/af0dd396-71a8-467a-36aa-9bb789ec8b00/public'); */
  background-size: cover 200%;
  background-position: center;
  padding-top: calc(var(--extraLarge) * 2.5);
  padding-bottom: calc(var(--extraLarge) * 2.5);
  box-sizing: border-box;
  overflow: hidden;
}
.ai-dungeon-landing-section.hero .ai-dungeon-landing-section-inner {
  box-sizing: border-box;
  height: 65vh;
  max-height: 65vh;
}
.ai-dungeon-landing-section.dark {
  padding-top: calc(var(--extraLarge) * 2.5);
  padding-bottom: calc(var(--extraLarge) * 2.5);
}

.ai-dungeon-landing-section-inner {
  max-width: 1016px;
  width: calc(100% - var(--c6));
  margin: var(--medium) auto;
  margin-bottom: 0;
  animation: fade-left 0s ease forwards;
  display: flex;
  flex-direction: column;
}

@keyframes aid-fade-hero {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-left {
  from {
    opacity: 0;
    transform: translateX(var(--medium));
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.aid-footer a {
  color: #fff;
  text-decoration: none;
  font-family: "IBM Plex Mono", sans-serif !important;
}

.aid-footer p {
  font-family: "IBM Plex Sans", sans-serif !important;
}

.aid-bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  object-fit: cover;
  filter: saturate(1.3);
  opacity: 0;
  animation: aid-fade-hero 2s ease forwards;
  
}

.hero-gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 10%,
    rgba(0, 0, 0, 0) 150%
  );
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.pathfinder-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 30%, rgba(0, 0, 0, 0) 70%);
}

.ember-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 10%, rgba(0, 0, 0, 0) 60%);
}

.forge-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 10%, rgba(0, 0, 0, 0) 60%);
}

.aid-top-nav {
  position: fixed;
  top: 0;
  z-index: 999;
  height: var(--c9);
  width: 100vw;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  box-sizing: border-box;
  padding: var(--medium);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0;
  overflow: hidden;
}

.aid-top-nav button {
  max-height: 100%;
  border-radius: 8px;
  padding: 24px;
}

.nav-bar-link {
  padding-left: var(--c2);
  padding-right: var(--c2);
  display: flex;
  text-decoration: none;
  color: #fff;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  font-family: "IBM Plex Sans", sans-serif;
}

.nav-link-icon {
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.nav-bar-link.active {
  color: #ffb83c;
}

.image-wrapper {
  position: relative;
  display: inline-block;
}

/* .aid-bg-video {
  display: block;
} */

.image-wrapper::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1%;
  background: linear-gradient(to top, black, transparent);
  pointer-events: none;
}

.nav-bar-link::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 0px;
  bottom: -16px;
  background-image: url(https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/eee11e59-d734-4eef-afa4-3f2e21161700/public);
  background-size: 100% 4px;
  background-repeat: no-repeat;
  background-position: -12px;
  transition: background-position 0.3s cubic-bezier(0.5, 0.5, 0, 1),
    transform 0.3s cubic-bezier(0.5, 0.5, 0, 1),
    opacity 0.3s cubic-bezier(0.5, 0.5, 0, 1),
    height 0.3s cubic-bezier(0.5, 0.5, 0, 1);
  transform: scaleX(0);
  opacity: 0;
}
.nav-bar-link.active::before {
  height: 4px;
  background-position: 0px;
  transform: scaleX(1);
  opacity: 1;
}

.update-card-image {
  border: 1px solid var(--dark-1);
  padding-top: 80%;
  background-color: var(--dark-1);
  background-size: cover;
  background-position: center;
}

.update-card-container {
  color: #fff;
  font-family: "IBM Plex Sans", sans-serif !important;
  text-decoration: none !important;
}

.update-icon {
  background-image: url(https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/87cdc7b9-e3a3-41fe-bbc1-c3bfef352900/public);
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.nav-mobile-visible {
  display: none;
}
.nav-bar-link.disabled {
  pointer-events: none;
}
a {
  text-decoration: none !important;
}

@media only screen and (max-width: 765px) {
  .ai-dungeon-landing-section.hero {
    padding-top: 65%;
    padding-bottom: 64px;
  }
  .ai-dungeon-landing-section.hero video {
    height: 80vh;
    transform: translateY(-10%) !important;
  }
  .ai-dungeon-landing-section.hero .ai-dungeon-landing-section-inner {
    max-height: 50vh;
  }
  .nav-bar-link-flex {
    gap: 4px !important;
    flex-direction: column !important;
  }
  .nav-mobile-hidden {
    display: none !important;
  }
  .nav-mobile-visible {
    display: flex;
  }
  .nav-bar-link {
    flex: 1;
    padding-left: var(--c0);
    padding-right: var(--c0);
  }
  .nav-bar-link-flex p {
    font-size: 12px !important;
  }
}
