/* TABLET BREAK */
@media only screen and (max-width: 1090px) {
  /*****************/
  /***** Grid ******/
  /*****************/
  .voyage-grid.tablet-col-8 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .voyage-grid.tablet-col-6 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .voyage-grid.tablet-col-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .voyage-grid.tablet-col-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .voyage-grid.tablet-col-2 {
    grid-template-columns: 1fr 1fr;
  }
  .voyage-grid.tablet-col-1 {
    grid-template-columns: 1fr;
  }
  .tablet-span-8 {
    grid-column: span 8;
  }
  .tablet-span-7 {
    grid-column: span 7;
  }
  .tablet-span-6 {
    grid-column: span 6;
  }
  .tablet-span-5 {
    grid-column: span 5;
  }
  .tablet-span-4 {
    grid-column: span 4;
  }
  .tablet-span-3 {
    grid-column: span 3;
  }
  .tablet-span-2 {
    grid-column: span 2;
  }
  .tablet-span-1 {
    grid-column: span 1;
  }
}

/* MOBILE BREAK */
@media only screen and (max-width: 765px) {
  :root {

  --mobile-size-modifier: 0px;


  --c1: calc(8px - var(--mobile-size-modifier)) !important;
  --c2: calc(16px - var(--mobile-size-modifier)) !important;
  --c3: calc(24px - var(--mobile-size-modifier)) !important;
  --c4: calc(32px - var(--mobile-size-modifier)) !important;
  --c5: calc(40px - var(--mobile-size-modifier)) !important;
  --c6: calc(48px - var(--mobile-size-modifier)) !important;
  --c7: calc(56px - var(--mobile-size-modifier)) !important;
  --c8: calc(64px - var(--mobile-size-modifier)) !important;
  --c9: calc(72px - var(--mobile-size-modifier)) !important;
  --c10: calc(80px - var(--mobile-size-modifier)) !important;
  --c11: calc(88px - var(--mobile-size-modifier)) !important;
  --c12: calc(96px - var(--mobile-size-modifier)) !important;
  --c13: calc(104px - var(--mobile-size-modifier)) !important;
  --c14: calc(112px - var(--mobile-size-modifier)) !important;
  --c15: calc(120px - var(--mobile-size-modifier)) !important;
  --c16: calc(128px - var(--mobile-size-modifier)) !important;
  --c17: calc(136px - var(--mobile-size-modifier)) !important;
  --c18: calc(144px - var(--mobile-size-modifier)) !important;
  --c19: calc(152px - var(--mobile-size-modifier)) !important;
  --c20: calc(160px - var(--mobile-size-modifier)) !important;
  --c21: calc(168px - var(--mobile-size-modifier)) !important;
  --c22: calc(176px - var(--mobile-size-modifier)) !important;
  --c23: calc(184px - var(--mobile-size-modifier)) !important;
  --c24: calc(192px - var(--mobile-size-modifier)) !important;
  --c25: calc(200px - var(--mobile-size-modifier)) !important;
  --c26: calc(208px - var(--mobile-size-modifier)) !important;
  --c27: calc(216px - var(--mobile-size-modifier)) !important;
  --c28: calc(224px - var(--mobile-size-modifier)) !important;
  --c29: calc(232px - var(--mobile-size-modifier)) !important;
  --c30: calc(240px - var(--mobile-size-modifier)) !important;
  --c31: calc(248px - var(--mobile-size-modifier)) !important;
  --c32: calc(256px - var(--mobile-size-modifier)) !important;
  --c33: calc(264px - var(--mobile-size-modifier)) !important;
  --c34: calc(272px - var(--mobile-size-modifier)) !important;
  --c35: calc(280px - var(--mobile-size-modifier)) !important;
  --c36: calc(288px - var(--mobile-size-modifier)) !important;
  --c37: calc(296px - var(--mobile-size-modifier)) !important;
  --c38: calc(304px - var(--mobile-size-modifier)) !important;
  --c39: calc(312px - var(--mobile-size-modifier)) !important;
  --c40: calc(320px - var(--mobile-size-modifier)) !important;
  --c41: calc(328px - var(--mobile-size-modifier)) !important;
  --c42: calc(336px - var(--mobile-size-modifier)) !important;
  --c43: calc(344px - var(--mobile-size-modifier)) !important;
  --c44: calc(352px - var(--mobile-size-modifier)) !important;
  --c45: calc(360px - var(--mobile-size-modifier)) !important;
  --c46: calc(368px - var(--mobile-size-modifier)) !important;
  --c47: calc(376px - var(--mobile-size-modifier)) !important;
  --c48: calc(384px - var(--mobile-size-modifier)) !important;
  --c49: calc(392px - var(--mobile-size-modifier)) !important;
  --c50: calc(400px - var(--mobile-size-modifier)) !important;
  --c51: calc(408px - var(--mobile-size-modifier)) !important;
  --c52: calc(416px - var(--mobile-size-modifier)) !important;
  --c53: calc(424px - var(--mobile-size-modifier)) !important;
  --c54: calc(432px - var(--mobile-size-modifier)) !important;
  --c55: calc(440px - var(--mobile-size-modifier)) !important;
  --c56: calc(448px - var(--mobile-size-modifier)) !important;
  --c57: calc(456px - var(--mobile-size-modifier)) !important;
  --c58: calc(464px - var(--mobile-size-modifier)) !important;
  --c59: calc(472px - var(--mobile-size-modifier)) !important;
  --c60: calc(480px - var(--mobile-size-modifier)) !important;
  --c61: calc(488px - var(--mobile-size-modifier)) !important;
  --c62: calc(496px - var(--mobile-size-modifier)) !important;
  --c63: calc(504px - var(--mobile-size-modifier)) !important;

  --extraSmall: calc(8px - var(--mobile-size-modifier)) !important;
  --small: calc(16px - var(--mobile-size-modifier)) !important;
  --medium: calc(24px - var(--mobile-size-modifier)) !important;
  --large: calc(40px - var(--mobile-size-modifier)) !important;
  --extraLarge: calc(64px - var(--mobile-size-modifier)) !important;

  --text-body-small: calc(12px - var(--mobile-size-modifier)) !important;
  --text-body-medium: calc(14px - var(--mobile-size-modifier)) !important;
  --text-body-large: calc(16px - var(--mobile-size-modifier)) !important;

  --text-header-small: calc(18px - var(--mobile-size-modifier)) !important;
  --text-header-medium: calc(22px - var(--mobile-size-modifier)) !important;
  --text-header-large: calc(33px - var(--mobile-size-modifier)) !important;

  --text-display: 46px;
  --text-display-large: 52px;
  }

  /*****************/
/** Page Wrapper */
/*****************/
  .voyage-page-wrapper {
    margin-top: calc(var(--nav-height) + 1px);
  }

  /*****************/
  /***** Grid ******/
  /*****************/
  .voyage-grid.mobile-col-8 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .voyage-grid.mobile-col-6 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .voyage-grid.mobile-col-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .voyage-grid.mobile-col-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .voyage-grid.mobile-col-2 {
    grid-template-columns: 1fr 1fr;
  }
  .voyage-grid.mobile-col-1 {
    grid-template-columns: 1fr;
  }
  .mobile-span-8 {
    grid-column: span 8;
  }
  .mobile-span-7 {
    grid-column: span 7;
  }
  .mobile-span-6 {
    grid-column: span 6;
  }
  .mobile-span-5 {
    grid-column: span 5;
  }
  .mobile-span-4 {
    grid-column: span 4;
  }
  .mobile-span-3 {
    grid-column: span 3;
  }
  .mobile-span-2 {
    grid-column: span 2;
  }
  .mobile-span-1 {
    grid-column: span 1;
  }

  /*****************/
  /** Navigation ***/
  /*****************/
  .nav-mobile-hidden {
    display: none;
  }
  .nav-mobile-visible {
    display: inline-block;
  }
  .nav-mobile-visible-flex {
    display: flex !important;
  }
  .mobile-nav-button {
    background-image: url('https://voyage-images.s3.us-east-2.amazonaws.com/menu.svg');
    height: var(--c4);
    width: var(--c4);
    background-position: center;
    background-size: 200%;
    position: absolute;
    left: var(--medium);
    cursor: pointer;
  }
  .voyage-top-navigation .voyage-top-navigation-item {
    display: none;
  }
  .voyage-mobile-navigation .voyage-top-navigation-item {
    display: flex;
    width: 100%;
    max-height: var(--nav-height);
    justify-content: flex-start;
    padding-left: var(--medium);
  }
  .voyage-mobile-navigation {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(20,21,22,0.9);
    backdrop-filter: blur(12px);
    opacity: 0;
    transform: translateX(-12px);
    pointer-events: none;
    transition: opacity .3s ease, transform .3s ease;
  }
  .voyage-mobile-navigation.visible {
    opacity: 1;
    pointer-events: all;
    transform: scale(1) translateY(0);
  }

  .voyage-top-navigation-item .activity-light {
    height: 100%;
    width: 4px;
    left: 0;
    border-radius: 0px 8px 8px 0px;
  }

  /*****************/
  /** Modal ***/
  /*****************/
  .modal-panel {
    position: relative;
    width: 100%;
    height: calc(100% - var(--c10));
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    bottom: 0;
    animation: modal-mobile 0.7s cubic-bezier(0.5, 0.5, 0, 1);
  }
  .modal-panel.alt {
    height: auto;
    position: unset;
    animation: modal 0.3s ease-out;
  }
  .modal-panel-content {
    flex: 1;
  }
  .modal-action-strip {
    flex-direction: column;
  }
  .modal-action-strip button {
    min-height: var(--c7);
  }
  .modal-bg {
    align-items: flex-end;
  }
}